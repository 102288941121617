import { Control } from 'ol/control';
import { createOlViewer } from '../viewportHelpers';
import { Resources } from '../../resources/resources';

/**
 * Creates a new brightness & contrast management control
 * @param {object} opt_options Options to initialize
 * @param {Viewport} opt_options.viewer Viewport instance this control belongs to
 * @param {ol.layer} opt_options.layer The main layer of the openlayer map
 * @param {Object} opt_options.stateManager The state manager to keep settings in sync
 */
export class ColorAdjustment extends Control {
    constructor(opt_options) {

        var options = opt_options || {};
        var layer = options.layer;
        var viewer = options.viewer;

        var element = document.createElement('div');
        super({
            element: element,
            target: options.target
        });

        this.brightness = 0;
        this.contrast = 1;
        this.gamma = 1;
        var this_ = this;

        if (layer) {
            //    layer.on('postcompose', function (event) {
            //        if (this_.brightness !== 0 || this_.contrast != 1) {
            //            applyFilter(event.context, "brightnesscontrast", { brightness: this_.brightness, contrast: this_.contrast });
            //        }
            //    });
        }
        // <button type="button" class="ol-zoom-in ol-has-tooltip"><span role="tooltip">Zoom in</span>+</button>
        this.btnbplus = document.createElement('button');
        this.btnbplus.className = "ol-brightness-in";
        this.btnbplus.innerHTML = "+";
        this.btnbicon = document.createElement('button');
        this.btnbicon.className = "ol-brightness-middle";
        this.btnbicon.innerHTML = "0";
        this.btnbicon.title = Resources.translate("Adjust brightness");
        this.btnbminus = document.createElement('button');
        this.btnbminus.className = "ol-brightness-out";
        this.btnbminus.innerHTML = "-";

        this.btncplus = document.createElement('button');
        this.btncplus.className = "ol-contrast-in";
        this.btncplus.innerHTML = "+";
        this.btncicon = document.createElement('button');
        this.btncicon.className = "ol-contrast-middle";
        this.btncicon.innerHTML = "1.00";
        this.btncicon.title = Resources.translate("Adjust contrast");
        this.btncminus = document.createElement('button');
        this.btncminus.className = "ol-contrast-out";
        this.btncminus.innerHTML = "-";

        this.btngplus = document.createElement('button');
        this.btngplus.className = "ol-gamma-in";
        this.btngplus.innerHTML = "+";
        this.btngicon = document.createElement('button');
        this.btngicon.className = "ol-gamma-middle";
        this.btngicon.innerHTML = "1.00";
        this.btngicon.title = Resources.translate("Adjust gamma");
        this.btngminus = document.createElement('button');
        this.btngminus.className = "ol-gamma-out";
        this.btngminus.innerHTML = "-";

        function updateHTML() {
            this_.btnbicon.innerHTML = this_.brightness.toFixed(0);
            this_.btncicon.innerHTML = this_.contrast.toFixed(2);
            this_.btncicon.innerHTML = this_.gamma.toFixed(2);

            viewer.setBrightnessContrastGamma(this_.brightness, this_.contrast, this_.gamma);

            this_.getMap().render();
        }

        this.btnbplus.addEventListener('click', function () {
            this_.brightness += 10;
            updateHTML();
        }, false);
        this.btnbminus.addEventListener('click', function () {
            this_.brightness -= 10;
            updateHTML();
        }, false);
        this.btnbicon.addEventListener('click', function () {
            this_.brightness = 0;
            updateHTML();
        }, false);

        this.btncplus.addEventListener('click', function () {
            this_.contrast += 0.125;
            updateHTML();
        }, false);
        this.btncminus.addEventListener('click', function () {
            this_.contrast -= 0.125;
            updateHTML();
        }, false);
        this.btncicon.addEventListener('click', function () {
            this_.contrast = 1;
            updateHTML();
        }, false);

        this.btngplus.addEventListener('click', function () {
            this_.gamma += 0.1;
            updateHTML();
        }, false);
        this.btngminus.addEventListener('click', function () {
            this_.gamma -= 0.1;
            updateHTML();
        }, false);
        this.btngicon.addEventListener('click', function () {
            this_.gamma = 1;
            updateHTML();
        }, false);


        element.className = 'ol-brightness-contrast ol-unselectable ol-control';
        element.appendChild(this.btnbminus);
        element.appendChild(this.btnbicon);
        element.appendChild(this.btnbplus);
        element.appendChild(this.btncminus);
        element.appendChild(this.btncicon);
        element.appendChild(this.btncplus);
        element.appendChild(this.btngminus);
        element.appendChild(this.btngicon);
        element.appendChild(this.btngplus);
    }

    /**
    * Updates the color parameters
    * @param {number} brightness - The brightness value
    * @param {number} contrast - The contrast value
    * @param {number} gamma - The gamma value
    **/
    update(brightness, contrast, gamma) {
        this.brightness = brightness;
        this.contrast = contrast;
        this.gamma = gamma;

        this.btnbicon.innerHTML = this.brightness.toFixed(0);
        this.btncicon.innerHTML = this.contrast.toFixed(2);
        this.btngicon.innerHTML = this.gamma.toFixed(2);
    };
}
