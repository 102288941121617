
import { checkBrowserCompatibility } from '../../view/helpers';
import { login } from './components';

/**
 * Holds information required to perform an automatic login against a PMA.core server
 * @typedef {Object} AutoLogin~serverCredentials
 * @property {string} serverUrl
 * @property {string} username
 * @property {string} password
 */
/**
 * Authenticates against a PMA.core server without user interaction. Upon creation, the instance will register itself automatically as an authentication provider for the given context.
 * @param  {Context} context
 * @param  {AutoLogin~serverCredentials[]} serverCredentials - Array of PMA.core server credentials this authentication provider can manage.
 * @tutorial 03-gallery
 */
export class AutoLogin {
    constructor(context, serverCredentials) {
        if (!checkBrowserCompatibility()) {
            return;
        }

        if (!(serverCredentials instanceof Array)) {
            console.error("Expected array of server credentials");
            return;
        }

        this.serverCredentials = serverCredentials;
        this.context = context;
        this.context.registerAuthenticationProvider(this);
    }
    /**
     * Authenticates against a PMA.core server. This method is usually invoked by the context itself and should rarely be used outside it.
     * @param  {string} serverUrl - The URL of the PMA.core server to authenticate against
     * @param  {function} [success]
     * @param  {function} [failure]
     * @returns {boolean} True or false depending on whether this instance has enough information to authenticate against this PMA.core server
     */
    authenticate(serverUrl, success, failure) {
        for (var i = 0, max = this.serverCredentials.length; i < max; i++) {
            if (this.serverCredentials[i].serverUrl == serverUrl) {

                login(
                    this.serverCredentials[i].serverUrl,
                    this.serverCredentials[i].username,
                    this.serverCredentials[i].password,
                    this.context.getCaller(),
                    success,
                    failure);

                return true;
            }
        }
        // Requested server is not handled by this provider
        return false;
    }
}