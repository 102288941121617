let _culture = "";
let _labels = {};

/**
 * Contains static methods that are used to translate text displayed by the PMA.UI framework
 * @namespace PMA.UI.Resources
 */

export const Resources = {
    /**
     * Static hashmap that holds one array per available culture, e.g. PMA.UI.Resources.Labels['en-US'] or PMA.UI.Resources.Labels.en-US
     * @type {Object}
     */
    Labels: _labels,

    /**
    * Sets the current culture
    * @param {string} culture - E.g. "en-US"
    */
    setCulture: function (culture) {
        _culture = culture;
    },

    interpolate: function (text, o) {
        return text.replace(/{([^{}]*)}/g,
            function (a, b) {
                var r = o[b];
                return typeof r === 'string' || typeof r === 'number' ? r : a;
            });
    },

    /**
     * Translates a string
     * @param {string} text - The text to translate. It can be a simple string literal or it can also contain values that should be interpolated such as "{pageCount} of {totalResultCount} items"
     * @param {object} [o] - If text needs interpolation, o then holds all the properties that should be replaced within it
     * @returns {string} The translated string
     */
    translate: function (text, o) {
        if (_culture !== "" && _labels[_culture].hasOwnProperty(text)) {
            text = _labels[_culture][text];
        }
        //else {
        //    console.log("Translation missing: '" + text + "'");
        //}

        if (typeof o === "object") {
            return this.interpolate(text, o);
        }
        else {
            return text;
        }
    }
}

