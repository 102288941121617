import { Control } from 'ol/control';

/**
 * Displays the copyright attributions of slide, if any
 * @param {object} opt_options Options to initialize the copyright attribution control
 * @param {string} [opt_options.className] Classname of copyright attribution
 * @param {string} [opt_options.html] HTML code of copyright attribution
 */
export class PathomationAttribution extends Control {
    constructor(opt_options) {
        var options = opt_options || {};

        var element = document.createElement('div');
        element.className = (options.className || "ol-attr") + ' ol-unselectable';
        element.innerHTML = options.html || "";

        super({
            element: element,
            target: options.target
        });
    }
}
