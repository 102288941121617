import { MouseWheelZoom } from 'ol/interaction';

export const MouseWheelZoomMode = {
    normal: "Normal",
    objectives: "Objectives",
}

export class PmaMouseWheelZoom extends MouseWheelZoom {

    constructor(options) {
        super(options);
        if (!this.hasOwnProperty("deltaPerZoom_")) {
            throw "deltaPerZoom_ not found";
        }

        this.deltaPerZoom_ = options.deltaPerZoom;

        this.mode = MouseWheelZoomMode.normal;
    }

    setMode(mode) {
        let view = this.getMap().getView();
        // if (view.getResolutions() == null || view.getResolutions().length == 0) {
        //     return;
        // }

        if (mode == MouseWheelZoomMode.normal) {
            view.setConstrainResolution(false);
            this.constrainResolution_ = false;
            this.mode = mode;
        } else {
            view.setConstrainResolution(true);
            this.constrainResolution_ = true;
            this.mode = mode;
        }
    }
}