import { checkBrowserCompatibility } from '../../view/helpers';

/**
 * Holds a session ID per PMA.core server URL. Used to skip authentication, if a session ID is already available.
 * @typedef {Object} SessionLogin~serverSessions
 * @property {string} serverUrl
 * @property {string} sessionId
 */
/**
 * Authenticates against a PMA.core server without user interaction. Upon creation, the instance will register itself automatically as an authentication provider for the given context.
 * @param  {Context} context
 * @param  {SessionLogin~serverSessions[]} serverSessions - Array of PMA.core server sessions.
 */
export class SessionLogin {
    constructor(context, serverSessions) {
        if (!checkBrowserCompatibility()) {
            return;
        }

        if (!(serverSessions instanceof Array)) {
            console.error("Expected array of server credentials");
            return;
        }

        this.serverSessions = serverSessions;
        this.context = context;
        this.context.registerAuthenticationProvider(this);
    }
    /**
     * Authenticates against a PMA.core server. This method is usually invoked by the context itself and should rarely be used outside it.
     * @param  {string} serverUrl - The URL of the PMA.core server to authenticate against
     * @param  {function} [success]
     * @param  {function} [failure]
     * @returns {boolean} True or false depending on whether this instance has enough information to authenticate against this PMA.core server
     */
    authenticate(serverUrl, success, failure) {
        for (var i = 0, max = this.serverSessions.length; i < max; i++) {
            if (this.serverSessions[i].serverUrl === serverUrl) {
                success(this.serverSessions[i].sessionId);
                return true;
            }
        }

        // Requested server is not handled by this provider
        return false;
    }
    /**
     * Updates the session id of a specified server
     * @param {string} serverUrl - The URL of the PMA.core server to authenticate against
     * @param {string} sessionId - The sessionId to update
    */
    updateSessionId(serverUrl, sessionId) {
        for (var i = 0, max = this.serverSessions.length; i < max; i++) {
            if (this.serverSessions[i].serverUrl === serverUrl) {
                this.serverSessions[i].sessionId = sessionId;
                break;
            }
        }
    }
}


