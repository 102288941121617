import json from "../../package.json";
const version = json.version;

export const getVersion = function () { return version; };

export const loginSupportsPost = function (pmaCoreVersion) {
    let usePost = false;
    if (pmaCoreVersion && pmaCoreVersion !== undefined && pmaCoreVersion !== null && pmaCoreVersion !== "") {
        const version = pmaCoreVersion.split(".").map(x => parseInt(x));
        if (version.length === 4 && (version[0] > 2 || (version[0] === 2 && version[3] >= 1836))) {
            usePost = true;
        }
    }

    return usePost;
};
