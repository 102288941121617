import { checkBrowserCompatibility } from '../../view/helpers';

/**
 * This callback should be called when the authentication to a server was successfull
 * @callback CustomLogin~authenticateSuccess
 * @param {string} sessionID - The session id acquired by authenticating to the server
 */
/**
 * This callback should be called when the authentication to a server failed
 * @callback CustomLogin~authenticateError
 * @param {object} error - The error occured when trying to authenticate to the server
 * @param {string} error.Message - The error message to display
 */
/**
 * The callback function used to authenticate to a server url
 * @callback CustomLogin~authenticateCallback
 * @param  {string} serverUrl - The URL of the PMA.core server to authenticate against
 * @param  {CustomLogin~authenticateSuccess} [success]
 * @param  {CustomLogin~authenticateError} [failure]
 * @returns {boolean} True or false depending on whether this instance has enough information to authenticate against this PMA.core server
 */
/**
 * Authenticates against a PMA.core server with a custom callback function. The caller should implement the callback method with the required parameters
 * that authenticates to a server. The implemented function should call the success/error callbacks that are passed as parameters and return a value indicating
 * whether this provider can handle authentication to the specified server
 * @param  {Context} context
 * @param {CustomLogin~authenticateCallback} authenticate
 */
export class CustomLogin {
    constructor(context, authenticate) {
        if (!checkBrowserCompatibility()) {
            return;
        }

        if ((typeof authenticate !== "function")) {
            console.error("Expected authenticate callback function");
            return;
        }

        this.authenticateCb = authenticate;
        this.context = context;
        this.context.registerAuthenticationProvider(this);
    }
    /**
     * Authenticates against a PMA.core server. This method is usually invoked by the context itself and should rarely be used outside it.
     * @param  {string} serverUrl - The URL of the PMA.core server to authenticate against
     * @param  {function} [success]
     * @param  {function} [failure]
     * @returns {boolean} True or false depending on whether this instance has enough information to authenticate against this PMA.core server
     */
    authenticate(serverUrl, success, failure) {
        return this.authenticateCb(serverUrl, success, failure);
    }
}