import { Control } from 'ol/control';

/**
 * Displays an interface that allows the user to take snapshot of current view
 * @param {object} opt_options Options to initialize the snapshot control
 * @param {Viewport} opt_options.pmaViewport Viewport instance this control belongs to
 * @param {string} [opt_options.tipLabel] Label for snapshot button
 */
export class Snapshot extends Control {
    constructor(opt_options) {

        var options = opt_options || {};
        var element = document.createElement('div');
        super({
            element: element,
            target: options.target
        });

        this.pmaViewport = options.pmaViewport;

        var anchor = document.createElement('a');
        anchor.href = '#';
        anchor.innerHTML = (options.tipLabel) ? options.tipLabel : 'Snapshot';

        var this_ = this;
        var handleClick = function (e) {
            e.preventDefault();

            if (this_.pmaViewport) {
                var url = this_.pmaViewport.getSnapshotUrl(false).url;
                window.open(url, '_blank');
            }
        };

        anchor.addEventListener('click', handleClick, false);
        anchor.addEventListener('touchstart', handleClick, false);


        element.className = 'ol-snapshot ol-unselectable';
        element.appendChild(anchor);
    }
}