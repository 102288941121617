/**
   * Viewer themes
   * @readonly
   * @enum {string}
   * @property {string} Themes.Default  OpenLayers default
   * @property {string} Themes.Classic Classic layout found in many desktop viewers
   * @property {string} Themes.Modern Modern layout compatible with web application UI
   */
export const Themes = {
    Default: "default",
    Classic: "classic",
    Modern: "modern"
};

/**
 * Custom button predefined locations
 * @readonly
 * @enum {string}
 * @property {string} ButtonLocations.N North of the viewport
 * @property {string} ButtonLocations.S South of the viewport
 * @property {string} ButtonLocations.E East of the viewport
 * @property {string} ButtonLocations.W West of the viewport
 * @property {string} ButtonLocations.NE North-East corner of the viewport
 * @property {string} ButtonLocations.NW North-West corner of the viewport
 * @property {string} ButtonLocations.SE South-East corner of the viewport
 * @property {string} ButtonLocations.SW South-West corner of the viewport
 **/
export const ButtonLocations = {
    N: "n",
    S: "s",
    E: "e",
    W: "w",
    NE: "ne",
    NW: "nw",
    SE: "se",
    SW: "sw",
};

/**
 * Available annotation types
 * @readonly
 * @enum {string}
 * @memberof PMA.UI.Types
 * @property {string} Annotation.Rectangle A rectangular area
 * @property {string} Annotation.Arrow A vector arrow
 * @property {string} Annotation.Line A line segment made of a start and end point
 * @property {string} Annotation.Icon A point with an icon image
 * @property {string} Annotation.Point A point
 * @property {string} Annotation.MultiPoint Multiple points
 * @property {string} Annotation.Freehand Free hand line
 * @property {string} Annotation.ClosedFreehand Free hand polygon
 * @property {string} Annotation.CompoundFreehand  Multiple free hand lines that are eventually connected together to form a closed freehand polygon
 * @property {string} Annotation.Ellipse Ellipse
 * @property {string} Annotation.Circle Circle
 * @property {string} Annotation.Polygon Polygon
 * @property {string} Annotation.LineString Multiple connected line segments
 */
export const Annotation = {
    Rectangle: "Rectangle",
    Arrow: "Arrow",
    Line: "Line",
    Icon: "Icon",
    Point: "Point",
    MultiPoint: "MultiPoint",
    Freehand: "Freehand",
    ClosedFreehand: "ClosedFreehand",
    CompoundFreehand: "CompoundFreehand",
    Ellipse: "Ellipse",
    Circle: "Circle",
    Polygon: "Polygon",
    LineString: "LineString"
};

/**
 * Available edit types
 * @readonly
 * @enum {string}
 * @memberof PMA.UI.Types
 * @property {string} Edit.Transform Scales and rotates an annotation
 * @property {string} Edit.Modify Changes shape of annotation using handles
 * @property {string} Edit.Wand Creates new annotation on seleced area
 * @property {string} Edit.Brush Changes shape of annotation using brush
 * @property {string} Edit.Eraser Changes shape of annotation using eraser
 */
export const Edit = {
    Transform: "Transform",
    Modify: "Modify",
    Wand: "Wand",
    Brush: "Brush",
    Eraser: "Eraser",
};

/**
 * Annotations states
 * @readonly
 * @enum {number}
 * @memberof PMA.UI.Types
 * @property {number} AnnotationState.Pristine Unchanged
 * @property {number} AnnotationState.Added Added but not saved yet
 * @property {number} AnnotationState.Modified Updated but not saved yet
 * @property {number} AnnotationState.Deleted Removed but not saved yet
 */
export const AnnotationState = {
    Pristine: 1,
    Added: 2,
    Modified: 3,
    Deleted: 4
};

/**
 * Available controls
 * @readonly
 * @enum {string}
 * @property {string} Controls.ZoomSlider The zoom slider control
 * @property {string} Controls.ScaleLine The scale line control
 * @property {string} Controls.Overview The overview control
 * @property {string} Controls.Barcode The barcode control
 * @property {string} Controls.Magnifier The magnifier control
 * @property {string} Controls.ColorAdjustments The brightness/contrast control
 * @property {string} Controls.LayerSwitch The control to turn layers on/off. Used for server side annotation layers
 * @property {string} Controls.DimensionSelector The control used to control z-stacks and timeframes
 * @property {string} Controls.Filename The filename control
 * @property {string} Controls.Snapshot The snapshot control
 * @property {string} Controls.Rotation The rotation control
 * @property {string} Controls.Attribution The attribution control
 * 
 */
export const Controls = {
    ZoomSlider: "zoomslider",
    ScaleLine: "scaleline",
    Overview: "overview",
    Barcode: "barcode",
    Magnifier: "magnifier",
    ColorAdjustments: "coloradjustments",
    LayerSwitch: "layerswitch",
    DimensionSelector: "dimensionselector",
    Filename: "filename",
    Snapshot: "shapshot",
    Rotation: "rotation",
    Attribution: "attribution",
    Fullscreen: "fullscreen"
};

/**
 * Default fill color used
 * @readonly
 * @memberof PMA.UI.View
 */
export const DefaultFillColor = 'rgba(255, 255, 255, 0.2)';

/**
 * Events fired by the viewport
 * @readonly
 * @enum {string}
 * @namespace View.Events
 */
export const Events = {
    /** 
     * Fires when the active layer, timeframe or channels change
     * @event View.Events#DimensionsChanged
    */
    DimensionsChanged: "change:dimension",
    /**
     * Fires when the the center, resolution (zoom) or rotation changes
     * @event View.Events#ViewChanged
    */
    ViewChanged: "viewchanged",
    /**
     * Fires when an error occurs while loading tiles
     * @event View.Events#TilesError */
    TilesError: "tileserror",
    /**
     * Fires when the slide cannot be loaded
     * @event View.Events#SlideLoadError */
    SlideLoadError: "SlideLoadError",
    /**
     * Fires when the file name control is clicked
     * @event View.Events#FilenameClick */
    FilenameClick: "click:filename",

    /**
     * Fires when an annotation layer has changed visibility/opacity
     * @event View.Events#AnnotationLayerChanged
     */
    AnnotationLayerChanged: "layer:change",
};

/**
 * Supported types of objective resolutions
 * @readonly
 * @enum {string}
 * @namespace View.ObjectiveResolutions
 * @property {string} ObjectiveResolutions.Min Minimum resolution
 * @property {string} ObjectiveResolutions.1X 1x
 * @property {string} ObjectiveResolutions.2X 2x
 * @property {string} ObjectiveResolutions.5X 5x
 * @property {string} ObjectiveResolutions.10X 10x
 * @property {string} ObjectiveResolutions.20X 20x
 * @property {string} ObjectiveResolutions.40X 40x
 * @property {string} ObjectiveResolutions."1:1" 1:1 Resolution
 * @property {string} ObjectiveResolutions.Max Maximum resolution
 */
export const ObjectiveResolutions = {
    "Min": "min",
    "1X": "1",
    "2X": "2",
    "5X": "5",
    "10X": "10",
    "20X": "20",
    "40X": "40",
    "1:1": "1:1",
    "Max": "max"
};