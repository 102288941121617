import { Control } from 'ol/control';
import { createOlViewer } from '../viewportHelpers';

/**
 * Displays an interface that allows the user to return to previous zoom
 * @param {object} opt_options Options to initialize the previous zoom control
 * @param {string} [opt_options.tipLabel] Label for previous zoom button
 * @param {ol.interaction.DragZoom} opt_options.dragZoom The dragzoom interaction of this map
 */

export class PrevZoom extends Control {
    constructor(opt_options) {
        var options = opt_options || {};
        var dragZoom = options.dragZoom;


        var handleClick = function (e) {
            e.preventDefault();
            e.stopPropagation();

            if ((" " + element.className + " ").indexOf(' disabled ') > -1) {
                return;
            }
            else {
                element.className += " disabled";
            }

            this.getMap().getView().setResolution(prevResolution);
        };



        var tipLabel = (options.tipLabel) ? options.tipLabel : 'Previous view';

        var label = (options.label) ? options.label : '\u2190';

        let label_ = document.createElement('span');
        label_.innerHTML = label;

        var button = document.createElement('button');
        button.type = 'button';
        button.title = tipLabel;
        button.appendChild(label_);


        var prevResolution = 1;

        var element = document.createElement('div');
        element.className = 'ol-prevZoom ol-control ol-unselectable disabled';
        element.appendChild(button);

        super({
            element: element,
            target: options.target
        });

        dragZoom.on('boxstart', (function (e) {
            if ((" " + element.className + " ").indexOf(' disabled ') > -1) {
                element.className = element.className.replace(/disabled/g, '');
            }

            prevResolution = this.getMap().getView().getResolution();
        }).bind(this));

        button.addEventListener('click', handleClick.bind(this), false);
        button.addEventListener('touchstart', handleClick.bind(this), false);
    }

    /**
    * Sets the OpenLayers map this control handles. This is automatically called by OpenLayers
    * @param {ol.Map} map 
    */
    setMap(map) {
        super.setMap(map);
    }
}





