import { Control } from 'ol/control';

/**
 * Creates a control to display the filename of a slide
 * @param {object} opt_options Options to initialize
 * @param {string | PMA.UI.View.Viewport~filenameCallback | undefined} opt_options.filename How to show the filename. If a string is passed this is displayed. 
 * If a callback function is passed this is called to get the filaname to show. If this is ommited the filename is automatically fetched via the slide info
 * @param {string} opt_options.onClick The event to call when the filename is clicked
 * @param {boolean} [opt_options.collapsed] Whether the control starts collapsed
 * @param {string} [opt_options.collapseLabel] The label to show when collapsed
 * @param {Object} [opt_options.stateManager] The state manager to keep settings in sync
 */
export class Filename extends Control {
    constructor(opt_options) {
        var options = opt_options || {};
        var element = document.createElement('div');
        super({
            element: element,
            target: options.target
        });

        this.stateManager = options.stateManager ? options.stateManager : null;
        if (this.stateManager) {
            if (!this.stateManager.fileName) {
                this.stateManager.fileName = {};
                this.stateManager.fileName.collapsed = (options.collapsed) ? options.collapsed : false;
            }

            this.collapsed_ = this.stateManager.fileName.collapsed === true;
        }
        else {
            this.collapsed_ = (options.collapsed) ? options.collapsed : false;
        }

        var collapseLabel = (options.collapseLabel) ? options.collapseLabel : '\u00AB';
        this.collapseLabel_ = document.createElement('a');
        this.collapseLabel_.href = '#';
        this.collapseLabel_.innerHTML = collapseLabel;

        var label = (options.label) ? options.label : '\u00BB';
        this.label_ = document.createElement('a');
        this.label_.href = '#';
        this.label_.innerHTML = label;

        var anchor = document.createElement('a');
        anchor.href = '#';
        var mainSpan = document.createElement('span');
        mainSpan.innerHTML = options.filename;
        anchor.appendChild(mainSpan);


        var handleClick = function (e) {
            e.preventDefault();
            if (typeof options.onClick === "function") {
                options.onClick();
            }
        };

        anchor.addEventListener('click', handleClick, false);
        anchor.addEventListener('touchstart', handleClick, false);


        element.className = 'ol-filename ol-unselectable' + (this.collapsed_ ? ' ol-collapsed' : '');
        element.appendChild(anchor);
        element.appendChild(this.collapseLabel_);
        element.appendChild(this.label_);

        this.handleCollapseClick = function (e) {
            if (e) {
                e.preventDefault();
            }

            if ((" " + element.className + " ").indexOf(' ol-collapsed ') > -1) {
                element.className = element.className.replace(/ol-collapsed/g, '');
            }
            else {
                element.className += ' ol-collapsed';
            }
        };

        this.collapseLabel_.addEventListener('click', this.handleCollapseClick, false);
        this.collapseLabel_.addEventListener('touchstart', this.handleCollapseClick, false);
        this.label_.addEventListener('click', this.handleCollapseClick, false);
        this.label_.addEventListener('touchstart', this.handleCollapseClick, false);
    }

    /** 
     * Gets the collapsed state of the control
     * @return {boolean} True if the control is currently collapsed
    */
    getCollapsed() {
        return (" " + this.element.className + " ").indexOf(' ol-collapsed ') > -1;
    };

    /** 
     * Sets the collapsed state of the control
     * @param {boolean} collapsed - True to collapse the control, otherwise false
    */
    setCollapsed(collapsed) {
        if (this.getCollapsed() != collapsed) {
            this.handleCollapseClick();
        }
    };
}
