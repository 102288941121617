import { login } from './components';
import $ from 'jquery';
import { Resources } from '../../resources/resources';
import { checkBrowserCompatibility } from '../../view/helpers';

/**
 * PMA.UI.Authentication contains UI and utility components that authenticate against PMA.core
 * @namespace PMA.UI.Authentication
 */

function loginClick(serverUrl, attempts) {
    var self = this;

    login(
        serverUrl,
        $("div.pma-ui-loginprompt input[type=text]").val(),
        $("div.pma-ui-loginprompt input[type=password]").val(),
        this.context.getCaller(),
        function (sessionId) {
            for (var i = 0; i < self.successCallbacks.length; i++) {
                self.successCallbacks[i](sessionId);
            }

            self.active = false;
            $("div.pma-ui-loginprompt").remove();
        },
        function (error) {
            if (!error.Message && error.Reason) {
                error.Message = error.Reason;
            }

            if (attempts < 2) {
                $("div.pma-ui-loginprompt .error").html(error.Message);
            }
            else {
                self.active = false;
                for (var i = 0; i < self.failureCallbacks.length; i++) {
                    self.failureCallbacks[i](error);
                }

                $("div.pma-ui-loginprompt").remove();
            }
        });
}
/**
* The callback function used to authenticate to a server url
* @callback PromptLogin~serverNameCallback
* @param  {string} serverUrl - The URL of the PMA.core server to find a friendly name for
* @returns {string} A friendly name for the specified server url if found or null
*/

export
    /**
     * Authenticates against a PMA.core server by prompting the user to enter credentials. Upon creation, the instance will register itself automatically as an authentication provider for the given context.
     * @param  {Context} context
     * @param {PromptLogin~serverNameCallback} serverNameCallback - A callback that takes a serverUrl as parameter and returns a frienly name for that server
     * @tutorial 04-tree
     */
    class PromptLogin {
    constructor(context, serverNameCallback) {
        if (!checkBrowserCompatibility()) {
            return;
        }

        this.context = context;
        this.context.registerAuthenticationProvider(this);
        this.active = false;
        this.serverNameCallback = serverNameCallback;
    }
    /**
     * Authenticates against a PMA.core server. This method is usually invoked by the context itself and should rarely be used outside it.
     * @param  {string} serverUrl - The URL of the PMA.core server to authenticate against
     * @param  {function} [success]
     * @param  {function} [failure]
     * @returns {boolean} - True or false depending on whether this instance has enough information to authenticate against this PMA.core server
     */
    authenticate(serverUrl, success, failure) {
        if (!this.active) {
            this.successCallbacks = [];
            this.failureCallbacks = [];

            if (typeof success === "function") {
                this.successCallbacks.push(success);
            }

            if (typeof failure === "function") {
                this.failureCallbacks.push(failure);
            }

            this.active = true;
            var self = this;
            var attempts = 0;

            var name = serverUrl;
            if (typeof this.serverNameCallback === "function") {
                var r = this.serverNameCallback(serverUrl);
                if (r && typeof r === "string") {
                    name = r;
                }
            }

            $("body").append("<div class='pma-ui-loginprompt'>" +
                "<div class='login-panel'>" +
                "<div>" +
                "<label>" + name + "</label>" +
                "</div>" +
                "<div>" +
                "<input type='text' placeholder='" + Resources.translate("Username") + "' />" +
                "</div>" +
                "<div>" +
                "<input type='password' placeholder='" + Resources.translate("Password") + "' />" +
                "</div>" +
                "<div class='error'></div>" +
                "<div class='controls'>" +
                "<input type='button' class='accept' value='" + Resources.translate("OK") + "' />" +
                "<input type='button' class='cancel' value='" + Resources.translate("Cancel") + "' />" +
                "</div>" +
                "</div>" +
                "</div>");

            $("div.pma-ui-loginprompt input[type=text], div.pma-ui-loginprompt input[type=password]").keypress(function (e) {
                if (e.which == 13) {
                    e.preventDefault();
                    loginClick.call(self, serverUrl, attempts++);
                }
            });

            $("div.pma-ui-loginprompt input.accept").click(function () {
                loginClick.call(self, serverUrl, attempts++);
            });

            $("div.pma-ui-loginprompt input.cancel").click(function () {
                self.active = false;
                for (var i = 0; i < self.failureCallbacks.length; i++) {
                    self.failureCallbacks[i]({ Message: Resources.translate("Login cancelled") });
                }

                $("div.pma-ui-loginprompt").remove();
            });
        }
        else {
            if (typeof success === "function") {
                this.successCallbacks.push(success);
            }

            if (typeof failure === "function") {
                this.failureCallbacks.push(failure);
            }
        }

        return true;
    }
}
